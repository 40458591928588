<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form size="small" ref="inputForm"
                 :model="inputForm"
                 :rules="rules" label-width="100px">
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="任务名称" prop="jobName">
                        <el-input v-model.trim="inputForm.jobName"
                                  :disabled="inputType"
                                  placeholder="请输入任务名称(限20字)"
                                  maxlength="20" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="标注模型" prop="modelId">
                        <el-select v-model="inputForm.modelId"
                                   :disabled="inputType"
                                   placeholder="请选择标注模型"
                                   style="width: 100%"
                                   clearable>
                            <el-option
                                v-for="item in modelLabelList"
                                :key="item.id"
                                :label="item.modelName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开始时间" prop="startTime">
                        <el-date-picker
                                style="width: 100%"
                                clearable
                                :disabled="inputType"
                                v-model="inputForm.startTime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="选择开始时间">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="结束时间" prop="endTime">
                        <el-date-picker
                                style="width: 100%"
                                clearable
                                :disabled="inputType"
                                v-model="inputForm.endTime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="选择结束时间">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="启动时间" prop="startUpTime">
                        <el-date-picker
                                style="width: 100%"
                                clearable
                                :disabled="inputType"
                                v-model="inputForm.startUpTime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="pickerOptions"
                                type="datetime"
                                placeholder="选择启动时间">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="任务描述" prop="remake">
                        <el-input v-model.trim="inputForm.remake"
                                  :disabled="inputType"
                                  placeholder="请输入任务描述(限30字)"
                                  maxlength="30" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-show="butType != 1">
                    <el-form-item label="标注图片" prop="imgList">
                        <resume-upload :disabledBut="inputType" ref="resume-upload" :filtButtonName="filtButtonName"
                                       :filtButton="true" :uploadNum="flieNumber" :allowFiles="fileType"
                                       @upFileDate="(data)=>getUpData(data)"></resume-upload>
                        <div class="flex_l_c" style="flex-wrap: wrap">
                            <div
                                    v-for="(item,index) in inputForm.imgList" :key="item.inputResource"
                                    style="position: relative"
                            >
                                <div class="el-icon-error" @click="deleteFile(item,index)"
                                     style="position:absolute; top: -8px;right: 8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                                <el-image
                                        style="width: 80px; height: 80px;margin-right: 15px"
                                        :src="item.inputResource"
                                        :fit="'cover'"
                                        :preview-src-list="[item.inputResource]"
                                >
                                    <div slot="error" class="image-slot">
                                        <el-image
                                                style="width: 36px; height: 36px"
                                                :src="require('@/assets/img/default.png')"
                                                :fit="'cover'">
                                        </el-image>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="text_center" v-if="butType != 1">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-no-more-click>提交</el-button>
        </div>
        <div class="text_center" v-else>
            <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
    name: "addManual",
    components: {ResumeUpload},
    data() {
        return {
            pickerOptions: {
                disabledDate(v) {
                    return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
                }
            },
            title: '新增图片手动标注',
            dialogVisible: false,
            inputForm: {
                jobName: '',
                modelId: '',
                startTime: '',
                endTime: '',
                startUpTime: '',
                remake: '',
                imgList: [],
                jobType: '1',
            },
            rules: {
                jobName: [
                    {required: true, message: '请输入任务名称', trigger: 'blur'},
                ],
                modelId: [
                    {required: true, message: '请选择标注模型', trigger: 'change'},
                ],
                startTime: [
                    {required: true, message: '请选择开始时间', trigger: 'change'},
                ],
                endTime: [
                    {required: true, message: '请选择结束时间', trigger: 'change'},
                ],
                startUpTime: [
                    {required: true, message: '请选择启动时间', trigger: 'change'},
                ],
                imgList: [
                    {type: 'array', required: true, message: '请上传要标注的图片', trigger: 'change'},
                ],
            },
            modelLabelList:[],
            inputType: false,
            butType: '0',//0新增 1详情 2修改

            filtButtonName: '新增图片',
            flieNumber: 10,
            fileType: ['PNG', 'JPG', 'JPEG'],
        }
    },

    mounted() {
        this.$axios(this.api.dyyg.dyygmodelGetDyygModel, {
            modelState:0,
            keyWord:'',
            current: 1,
            size: 99,
        }, 'post').then(res => {
            if (res.status) {
                this.modelLabelList = res.data.records
            }
        })
    },

    methods: {
        init(num, row) {
            this.inputType = false
            this.butType = num
            if (num != 0) {
                if (num == 1) {
                    this.title = '查看图片手动标注'
                    this.inputType = true
                }
                if (num == 2) {
                    this.title = '修改图片手动标注'
                }
                this.$axios(this.api.dyyg.dyygmodelGetById + row.id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.inputForm = res.data
                        this.$set(this.inputForm, 'imgList', res.data.modelResources)
                        this.dialogVisible = true
                        this.$nextTick(() => {
                            this.$refs['resume-upload'].setOldFile(this.inputForm.imgList)
                        })
                    }
                })
            } else {
                this.dialogVisible = true
            }

        },

        getUpData(data) {
            this.inputForm.imgList = data.map((item, index) => {
                if (item.upDate) {
                    this.$set(item.upDate, 'fileID', item.id)
                    this.$set(item.upDate, 'fileSort', index + 1)
                    this.$set(item.upDate, 'masterView', 1)
                    this.$set(item.upDate, 'fileSize', item.origSize)
                    this.$set(item.upDate, 'inputResource', item.upDate.netUrl)
                    return item.upDate
                } else {
                    return item
                }
            })
        },

        deleteFile(row, index) {
            this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.inputForm.imgList.splice(index, 1)
                this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
            }).catch(() => {
            })
        },

        sureData() {
            if (this.toDateSize(this.inputForm.startTime, this.inputForm.endTime)) {
                this.$message.error('结束时间必须大于开始时间')
                return
            }
            if (this.toDateSize(new Date(), this.inputForm.startUpTime)) {
                this.$message.error('启动时间必须大于当前时间')
                return
            }
            let setData = JSON.parse(JSON.stringify(this.inputForm))
            this.$delete(setData, 'imgList')
            let modelResourceList = this.inputForm.imgList.map(item => {
                return item.inputResource
            })
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (this.inputForm.id) {
                        this.$axios(this.api.dyyg.dyygmodelUpdateById, {
                            ...setData,
                            modelResourceList,
                        }, 'put').then((res) => {
                            if (res.status) {
                                this.$message.success('修改成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$axios(this.api.dyyg.dyygmodelSave, {
                            ...setData,
                            modelResourceList,
                        }, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        //时间大小比较
        toDateSize(date1, date2) {
            date1 = new Date(date1).getTime()
            date2 = new Date(date2).getTime()
            if (date1 > date2) {
                return true
            } else {
                return false
            }
        },

        handleClose() {
            if (this.inputForm.imgList.length) {
                this.inputForm.imgList.forEach(item => {
                    this.$refs["resume-upload"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
                })
                this.inputForm.imgList = []
            }
            this.$refs.inputForm.resetFields()
            this.inputForm = {
                jobName: '',
                modelId: '',
                startTime: '',
                endTime: '',
                startUpTime: '',
                remake: '',
                imgList: [],
                jobType: '1',
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>